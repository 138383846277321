import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <h1>Not Found</h1>
    <p>Sorry, there is no page at this URL.</p>
  </Layout>
);

export default NotFoundPage;
